<template>
  <div
    class="bg-foundation rounded-md overflow-hidden shadow hover:shadow-md transition"
  >
    <div class="flex items-center px-2 py-5 justify-between bg-blue-500/10">
      <div class="flex items-center space-x-2">
        <div class="font-bold flex space-x-1">
          <a
            v-tippy="'View details'"
            :href="automationRoute(automation.automationId)"
            class="transition hover:text-primary flex space-x-1"
          >
            <PlayCircleIcon class="w-4 mr-1" />
            {{ automation.automationName }}
          </a>
        </div>
        <div v-if="projectData" class="text-xs flex items-center mt-[1px]">
          on
          <FormButton
            text
            size="xs"
            external
            :to="buildProjectUrl(automation.projectId)"
            target="_blank"
            :icon-right="ArrowUpRightIcon"
          >
            {{ projectData.projectName }}
          </FormButton>
          @
          <FormButton
            text
            size="xs"
            external
            :to="buildModelUrl(automation.projectId, automation.modelId)"
            target="_blank"
            :icon-right="ArrowUpRightIcon"
          >
            {{ projectData.modelName }}
          </FormButton>
          <span class="ml-1 text-foreground-2">
            {{ runs.data.value?.totalCount }} runs
          </span>
        </div>
        <div>
          <div
            v-if="!automation.enabled"
            v-tippy="'Automation disabled'"
            class="rounded-full w-4 h-4 bg-danger flex items-center justify-center text-foreground-on-primary"
          >
            <PauseIcon class="w-3" />
          </div>
        </div>
      </div>
      <div class="text-right">
        <FormButton
          text
          size="sm"
          class="px-0"
          :to="automationRoute(automation.automationId)"
        >
          Details
        </FormButton>
      </div>
    </div>
    <CommonAlert v-if="invalidAutomationReason" color="danger">
      <template #title>{{ invalidAutomationReason }}</template>
    </CommonAlert>
    <div>
      <AutomationsRunsTable
        v-if="runs.data.value?.items?.length !== 0"
        :runs="runs.data.value?.items || []"
        :shadow="false"
      />
      <div
        v-else
        class="text-xs text-foreground-2 text-center py-4 px-2 border-t border-t-foundation-page flex items-center justify-between"
      >
        <div>No runs have been executed yet for this automation.</div>
      </div>

      <FormButton
        v-if="
          runs.data.value?.items.length !== 0 &&
          runs.data.value?.totalCount &&
          runs.data.value?.totalCount > runsLimitQuery
        "
        block
        text
        class="w-full my-1 text-foreground"
        size="sm"
        :to="automationRoute(automation.automationId)"
      >
        View All
      </FormButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ArrowUpRightIcon, PauseIcon, PlayCircleIcon } from '@heroicons/vue/24/solid'
import { automationRoute } from '~/lib/common/core/helpers/route'
import {
  useGetAutomationRuns,
  type GetAutomationsItem
} from '~/lib/frontend/automations/composables/management'
import { useProjectData } from '~/lib/frontend/automations/composables/projectData'
import {
  useSpeckleModelUrl,
  useSpeckleProjectUrl
} from '~/lib/frontend/core/composables/route'

const buildProjectUrl = useSpeckleProjectUrl()
const buildModelUrl = useSpeckleModelUrl()

const props = defineProps<{
  automation: GetAutomationsItem
}>()

const { invalidAutomationReason, projectData } = useProjectData(
  computed(() => props.automation)
)

const runsLimitQuery = 5

const runs = useGetAutomationRuns(
  {
    aId: props.automation.automationId
  },
  { query: { limit: runsLimitQuery } }
)
</script>
